<template>
  <div class="table-pack-footer offset-left-2 offset-right-2">
    <a v-if="!isDirect" class="btn btn-default pull-left" :href="baseUrl">
      <i class="icon icon-arrow-left"></i>
      &nbsp;繼續購物
    </a>
    <p v-if="showShipFare" class="has-inline sub pull-center">
      本島運費：購物滿
      <b class="notice">${{ shipFareFreeLimit }}</b>
      元，免收運費
      <b class="notice">${{ shipFare }}</b>
      元
      <br />
      (實付金額：需扣除優惠券、神腦幣)
    </p>
    <a
      class="btn btn-secondary pull-right"
      href="javascript:void(0);"
      v-bind:class="[cartMainSubmitBtnDisabled ? 'disabled' : '']"
      @click="$emit('check-out')">
      結帳
    </a>
    <login-modal @check-out="$emit('check-out')" />
  </div>
</template>

<script>
import LoginModal from '@/components/common/modals/login-modal.vue';

const data = function () {
  return {
    baseUrl: process.env.VUE_APP_ONLINE,
  };
};

const props = {
  isDirect: Number,
  showShipFare: Boolean,
  shipFare: Number,
  shipFareFreeLimit: Number,
  cartMainSubmitBtnDisabled: Boolean,
};

const components = {
  LoginModal,
};

export default {
  name: 'TablePackFooter',
  components,
  data,
  props,
};
</script>
