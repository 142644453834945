<template>
  <div class="table-pack gray">
    <TablePackHeader :isDirect="isDirect" :cartType="cartType"></TablePackHeader>
    <div class="table-pack-body">
      <!-- 商品列表 -->
      <div class="css-table white middle stackable">
        <!-- thead-->
        <div class="css-tr">
          <div class="css-th">商品名稱</div>
          <div class="css-th align center w-f4">規格</div>
          <div class="css-th align center w-f6">售價</div>
          <div class="css-th align center w-f8">數量</div>
          <div class="css-th align center w-f6">小計</div>
          <div class="css-th align center w-f6" v-if="!isDirect">變更</div>
          <div class="css-th align center w-f6" v-if="isDirect">
            <span>&nbsp;</span>
          </div>
        </div>
        <div v-for="(item, index) in getCartDetail" :key="`cart-${cartType}-${index}`" class="css-tr">
          <!-- 商品名稱 -->
          <div class="css-td">
            <a v-if="hasLink(item.pcLink)" class="img-rind pull-left" :href="`${baseUrl}${item.pcLink}`">
              <img class="thumbnail small" alt="商品圖" :src="item.masterImage" />
            </a>
            <span v-else class="img-rind pull-left">
              <img class="thumbnail small" alt="商品圖" :src="item.masterImage" />
            </span>
            <div class="info">
              <h5 class="title" v-if="hasLink(item.pcLink)">
                <a :href="`${baseUrl}${item.pcLink}`">{{ item.productName }}</a>
              </h5>
              <h5 class="title" v-else>
                {{ item.productName }}
              </h5>
              <div class="title-note tag-text-danger" v-if="item.packQuantity > 1">
                此商品為 {{ item.packQuantity }} 件組
              </div>
              <div class="discount">
                <CartTag :item="item" />
                <!-- 優惠方案 -->
                <div class="coupon" v-if="isMajorItem(item.cartTag) && isShowPricingPlans(item)">
                  <span class="label pull-left">優惠方案</span>
                  <div
                    :class="['dropdown-toggle select-sm', { 'c-select': item.couponDesc }]"
                    @click="chooseCoupons(item)">
                    {{ item.couponDesc ? item.couponDesc : '選擇使用優惠方案' }}
                  </div>
                  <p class="warning w-note">* 折扣與優惠僅能擇一使用</p>
                </div>
                <cycle-order :is-mobile="false" :item="item" :item-index="index"></cycle-order>
              </div>
            </div>
          </div>
          <!-- 規格 -->
          <div class="css-td align center w-f4">{{ item.productSpec }}</div>
          <!-- 售價 -->
          <div class="css-td align center w-f6">
            <div>
              <span v-if="isShowDiscountTag(item)" class="tag tag-event-outline tag-mini tag-price"> 折扣價 </span>
              {{ item.finalPrice | dollar }}
            </div>
          </div>
          <!-- 數量 -->
          <div v-if="isMajorItem(item.cartTag)" class="css-td align center w-f8">
            <div class="number-group shrink">
              <button class="number-less" @click="changeQty(item, 'reduce')">
                <i class="icon icon-minus"></i>
              </button>
              <input
                class="number-view"
                type="number"
                :value="item.productQty"
                @change="customQty($event, item)"
                :max="item.limitQuantity"
                min="1"
                maxlength="2" />
              <button class="number-plus" @click="changeQty(item, 'increase')">
                <i class="icon icon-plus"></i>
              </button>
            </div>
          </div>
          <div v-else class="css-td align center w-f8">
            {{ item.productQty }}
          </div>
          <ItemTotal
            :isUseCoupon="isUseCoupon(item.couponId)"
            :finalPrice="item.finalPrice"
            :couponDiscount="item.couponDiscount"
            :qty="item.productQty"></ItemTotal>
          <div class="css-td align center w-f6">
            <div v-if="isFavorOrDelete(item)">
              <button
                v-if="isDelete(item.isDelete)"
                @click="deleteProducts(item)"
                class="btn btn-default btn-sm btn-block">
                刪除
              </button>
              <button
                v-if="isFavor(item.isFavor)"
                @click="collectProducts(item)"
                class="btn btn-default btn-sm btn-block">
                移到收藏夾
              </button>
            </div>
          </div>
        </div>
      </div>
      <CouponModal :mart="currentCart" @on-pricing-change="handlePriceChange" />
      <!-- 金額明細 (活動折扣、紅利、HamiPoint、小計總和) -->
      <DiscountTotal
        :is-auth-hami-point="isAuthHamiPoint"
        :format-point-money="formatPointMoney | dollar"
        :hami-point="hamiPoint"
        :point-money="pointMoney | dollar"
        :point-max-exchange-rate="pointMaxExchangeRate"
        :subTotal="subTotal | dollar"
        :is-show-hami-point-block="isShowHamiPointBlock"></DiscountTotal>
      <!-- 金額總計 -->
      <div class="table-total offset-left-2 offset-right-2">
        <div class="amount">
          <span class="label">商品總金額</span>
          <span class="price">{{ amount | dollar }}</span>
        </div>
      </div>
    </div>
    <!-- 結帳 -->
    <TablePackFooter
      :isDirect="isDirect"
      :show-ship-fare="showShipFare"
      :shipFare="getShipFare.shipFare"
      :shipFareFreeLimit="getShipFare.shipFareFreeLimit"
      :cartMainSubmitBtnDisabled="this.cartMainSubmitBtnDisabled"
      @check-out="checkOut()"></TablePackFooter>
  </div>
</template>

<script>
import { dollar, NumberWithPoint } from '@/helpers/format/dollar';
import { openModal } from '@/helpers/modal';
import { getState } from '@/helpers/vuex';
import { cartMainPartial } from '@/mixins/cart/main/partial';
import { cartMainMountedMixin } from '@/mixins/cart/main/mounted';
import CycleOrder from '@/components/common/cycle-order.vue';
import DiscountTotal from '@/components/desktop/main/discount-total.vue';
import ItemTotal from '@/components/desktop/main/item-total.vue';
import TablePackFooter from '@/components/desktop/main/table-pack-footer.vue';
import TablePackHeader from '@/components/desktop/main/table-pack-header.vue';
import CouponModal from '@/components/desktop/main/coupon-modal.vue';
import CartTag from '@/components/desktop/main/cart-tag.vue';

// 是否為預購車 Integer
const isDirect = getState('CartDetail', 'isDirect');

// 是否有使用優惠券
const isUseCoupon = (id) => id !== '';

const methods = {
  isUseCoupon,
  chooseCoupons(item) {
    this.currentCart = { ...item };
    openModal('CouponModal');
  },
  handlePriceChange(couponId) {
    const cartIndex = this.getCartDetail.findIndex((cart) => cart.detailId === this.currentCart.detailId);
    const payload = {
      couponId,
      cartIndex,
    };
    this.onPricingChange(payload);
  },
};

const computed = {
  isDirect,
};

const filters = {
  dollar,
  NumberWithPoint,
};

const props = {
  // 購物車類型
  cartType: String,
};

const components = {
  CycleOrder,
  ItemTotal,
  DiscountTotal,
  TablePackHeader,
  TablePackFooter,
  CouponModal,
  CartTag,
};

const data = () => ({
  currentCart: {},
});

export default {
  name: 'MartList',
  mixins: [cartMainMountedMixin, cartMainPartial],
  props,
  data,
  methods,
  computed,
  components,
  filters,
};
</script>
