<template>
  <div class="table-pack-head">
    <div class="nav-tabs nav-tabs-cart">
      <ul v-if="isDirect" class="nav-tabs-list js-nav-tabs-list">
        <li class="nav-tabs-caption spacing bottom">
          <i class="icon icon-nav-cart"></i>
          <span class="spacing">購物車明細</span>
        </li>
        <li class="nav-utm" v-if="utm">
          {{ utm }}
        </li>
      </ul>
      <ul v-else class="nav-tabs-list js-nav-tabs-list">
        <li class="nav-tabs-caption">
          <i class="icon icon-nav-cart"></i>
          <span class="spacing top">購物車明細</span>
        </li>
        <li
          class="nav-tabs-item"
          v-for="(item, index) in cartInfo"
          :key="index"
          :class="{ active: isActive(item.cartType) }">
          <router-link :to="{ path: '/cart/main', query: { type: item.cartType } }" class="nav-link">
            {{ item.cartName }}
            <span class="value spacing left mini">({{ item.count }})</span>
          </router-link>
        </li>
        <li class="nav-utm" v-if="utm">
          {{ utm }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { commitMutation, renderState } from '@/helpers/vuex';

const props = {
  // 是否為購物車
  isDirect: Number,
  cartType: String,
};

// tab className active
const isActive = function (toCartType) {
  const thisCartType = this.$route.query.type;
  // for remove item -> isRemoveProduct() from partial.js
  commitMutation('CartDetail', 'cartType', thisCartType);
  return thisCartType === toCartType;
};

const utm = () => renderState('CartDetail', 'utm');

// 購物車列表
const cartInfo = () => renderState('Cart', 'cartInfo');

const methods = {
  isActive,
};

export default {
  name: 'TablePackHeader',
  computed: {
    cartInfo,
    utm,
  },
  methods,
  props,
};
</script>
