<template>
  <modal
    :isModalStatic="true"
    componentName="CouponModal"
    :isHideHeaderCloseButton="true"
    firstButtonText="不使用"
    secondButtonText="確定"
    @cancel="cancelHandler"
    @firstFn="cancelHandler"
    @secondFn="onPricingChange">
    <template v-slot:title>
      <h3 class="text-left">選擇使用優惠方案</h3>
    </template>
    <template v-slot:body>
      <div class="css-table middle fn-sm">
        <div class="css-tr">
          <div class="css-th">折扣說明</div>
          <div class="css-th text-center w-f8">折扣後金額</div>
          <div class="css-th text-center w-f6">使用</div>
        </div>
        <div
          :class="[
            'css-tr',
            { 'cart-c-checked': coupon.value === couponId },
            { 'cart-list-later': coupon.value === 'later' },
          ]"
          v-for="(coupon, couponIndex) in coupons"
          :key="couponIndex">
          <!-- 優惠券(才有couponId) -->
          <template v-if="isHasCouponId(coupon)">
            <div class="css-td">
              <div class="price">
                <b class="fn-root">{{ formatDollar(coupon.itemCoupon) }}</b>
              </div>
              <div class="price fn-mini pd-c-lh-s">
                {{ coupon.isAmountLimit === 'Y' ? `適用商品滿$${coupon.threshold}元可用` : '指定商品適用' }}
              </div>
              <div class="text">{{ coupon.couponTitle }}</div>
              <div class="pd-c-date fn-mini">
                {{ coupon.value === 'later' ? `生效時間${coupon.startDate}` : `有效期限${coupon.endDate}` }}
              </div>
            </div>
            <div class="css-td text-center w-f8">
              <b class="price fn-root cart-c-p">{{ formatDollar(coupon.discountPrice) }}</b>
            </div>
          </template>
          <!-- 員購價 -->
          <template v-else>
            <div class="css-td">
              <div class="price">
                <b class="fn-root">{{ coupon.couponDesc }}</b>
              </div>
            </div>
            <div class="css-td text-center w-f8">
              <b class="price fn-root cart-c-p">{{ formatDollar(mart.empPrice) }}</b>
            </div>
          </template>
          <div class="css-td text-center w-f6">
            <template v-if="coupon.value === 'later'">
              <div class="css-td text-center w-f6 border-b-0">
                <b>稍後使用</b>
              </div>
            </template>
            <template v-else-if="coupon.value === 'used'">
              <div class="css-td text-center w-f6 border-b-0">
                <b class="danger">(已使用)</b>
              </div>
            </template>
            <template v-else>
              <input
                class="cart-c-checkbox"
                type="radio"
                :id="`coupon${couponIndex}`"
                :value="coupon.value"
                v-model="couponId" />
              <label class="cart-c-checkbox-l" :for="`coupon${couponIndex}`"></label>
            </template>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>
<script>
import { watch } from 'vue';
import { useCoupon } from '@/composables/coupon';
import Modal from '@/components/common/common-modal.vue';

export default {
  name: 'CouponModal',
  components: {
    Modal,
  },
  props: {
    mart: Object,
  },
  setup(props, target) {
    const { onPricingChange, cancelHandler, isHasCouponId, coupons, filterCoupon, formatDollar, couponId } = useCoupon(
      props,
      target
    );

    watch(
      () => props.mart,
      (newMart) => {
        // 顯示user 上一輪選的值
        couponId.value = '';
        if (newMart.couponId) {
          couponId.value = newMart.couponId;
        }
        coupons.value = filterCoupon(newMart);
      }
    );

    return {
      onPricingChange,
      cancelHandler,
      isHasCouponId,
      coupons,
      filterCoupon,
      formatDollar,
      couponId,
    };
  },
};
</script>
<style scoped>
/* fix: 已使用/稍後使用文字下方不明底線 */
.border-b-0 {
  border-bottom-width: 0;
}
</style>
