<template>
  <modal
    componentName="EdenredModal"
    modalSizeCssClass="sm"
    bodyCssClasses="Eden_box"
    :isModalStatic="true"
    :isHideFooter="true"
    @cancel="$emit('cancel')"
    @firstFn="$emit('cancel')">
    <template v-slot:body>
      <h3 class="align center">
        請輸入神腦的 Edenred 即享券序號
        <br />
        進行驗證序號
      </h3>
      <div class="spacing bottom css-table pure pull-center">
        <div class="css-tr tr-Eden">
          <div class="css-th align right th-Eden">
            <label>
              序號
              <br />
            </label>
          </div>
          <div class="css-td align left">
            <span>SED</span>
            <input
              class="middle spacing"
              type="text"
              maxlength="12"
              placeholder="請輸入 SED 後 12 碼數字"
              v-model.trim="setSerial" />
            <b v-if="isEdenredHasError" class="notice fn-sm"> * {{ edenredErrorTxt }} </b>
          </div>
        </div>
        <div class="css-tr tr-Eden" v-if="isShowAvailableBalance">
          <div class="css-th align right th-Eden">
            <label>
              可用餘額
              <br />
            </label>
          </div>
          <div class="css-td">
            <span class="price">{{ getThisEdenredBalance | dollar }}</span>
          </div>
        </div>
      </div>
      <!-- 宜睿的buttons html格式和按下驗證序號後的行為跟其他modal不一樣，所以沒共用 -->
      <div class="modal-footer Eden_top">
        <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('cancel')">回購物車</button>
        <button type="button" class="btn btn-query" @click="checkSerialNumberAndFetchEdenred(setSerial)">
          驗證序號
        </button>
      </div>
      <ol class="border bottom">
        <li class="align left">
          Edenred 即享券需先驗證序號，確認餘額，若驗證有誤請依錯誤說明，與相關單位確認問題再繼續進行結帳。
        </li>
        <li class="align left">同一筆訂單，可驗證多張序號，將依序號驗證順序進行折抵訂單金額。</li>
      </ol>
    </template>
    <template v-slot:footer>
      <h4>
        預計於本次交易進行抵用
        <br />
        共{{ getEdenredTotalCount }}筆序號，合計可用餘額
        <span class="price">{{ edenredBalanceTotal | dollar }}</span>
      </h4>
      <div class="css-table fn-sm middle" v-if="!isUserNotOwnedEdenredForShowText">
        <div class="css-tr">
          <div class="css-th text-center">驗證順序</div>
          <div class="css-th">已驗證序號</div>
          <div class="css-th">可用餘額</div>
          <div class="css-th"></div>
        </div>
        <div class="css-tr" v-for="(item, index) in getEdenredGroup" :key="index">
          <div class="css-td text-center">{{ index + 1 }}</div>
          <div class="css-td">{{ item.serial }}</div>
          <div class="css-td">{{ item.balance }}</div>
          <div class="css-td">
            <a class="btn btn-default btn-sm" @click="deleteThisEdenred(item)"> 刪除 </a>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { getState } from '@/helpers/vuex';
import { dollar } from '@/helpers/format/dollar';
import {
  getEdenredGroup,
  checkSerialNumberAndFetchEdenred,
  getEdenredTotalCount,
  edenredBalanceTotal,
  isUserNotOwnedEdenredForShowText,
  deleteThisEdenred,
  isShowAvailableBalance,
} from '@/helpers/edenred';
import Modal from '@/components/common/common-modal.vue';

export default {
  name: 'edenred-modal',
  components: {
    Modal,
  },
  data() {
    return {
      setSerial: '',
    };
  },
  filters: {
    dollar,
  },
  computed: {
    isShowAvailableBalance,
    isEdenredHasError: getState('CartDetail', 'edenredIsError'),
    edenredErrorTxt: getState('CartDetail', 'edenredErrorTxt'),
    getThisEdenredBalance: getState('CartDetail', 'thisEdenredBalance'),
    getEdenredGroup,
    getEdenredTotalCount,
    edenredBalanceTotal,
    isUserNotOwnedEdenredForShowText,
  },
  methods: {
    deleteThisEdenred,
    checkSerialNumberAndFetchEdenred,
  },
};
</script>
