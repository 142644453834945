<template>
  <div data-id="productBroseItem" class="product product-view">
    <a :href="`${baseUrl}${mart.url}`" @click="sendSelectItemForGA(mart)">
      <img data-content="img" :alt="mart.alt" :src="mart.image" />
    </a>
    <div class="content">
      <div class="tags-group product-tags"></div>
      <div class="title cycle-title-box">
        <a
          class="cycle-title-link"
          :href="`${baseUrl}${mart.url}`"
          data-content="nameUrl"
          @click="sendSelectItemForGA(mart)">
          <img
            v-if="mart.martSalesType === 8"
            data-content="saleTypeImg"
            class="cycle-icon"
            alt="週期購"
            src="/images/icons/icon_cycle.png" />
          <span>{{ mart.item_name }}</span>
        </a>
      </div>
      <div class="price">
        <del class="fn-sm" data-content="originPrice">
          {{ showOriPrice }}
        </del>
        <b class="fn-md" data-content="salePrice">
          {{ showFinalPrice }}
        </b>
      </div>
    </div>
  </div>
</template>

<script>
import { sendSelectItemForGA } from '@/helpers/tracking/ga';

const url = process.env.VUE_APP_ONLINE;

const props = {
  mart: Object,
};
export default {
  name: 'sliderItem',
  props,
  data() {
    return {
      baseUrl: url,
    };
  },
  computed: {
    isShowSuggestedPrice() {
      return this.mart.oriPrice > this.mart.finalPrice;
    },
    showOriPrice() {
      return this.isShowSuggestedPrice ? `$${this.mart.oriPrice.toLocaleString()}` : '';
    },
    showFinalPrice() {
      return `$${this.mart.finalPrice.toLocaleString()}` || '';
    },
  },
  methods: {
    sendSelectItemForGA,
  },
};
</script>
