import Bowser from 'bowser';
import { getURLParameters } from '@/helpers/string';

const ua = Bowser.getParser(window.navigator.userAgent);
const browser = ua.getBrowser();
const isChrome = browser.name === 'Chrome';
const isFirefox = browser.name === 'Firefox';
const isEdge = browser.name === 'Microsoft Edge';
const isIE11 = browser.name === 'Internet Explorer' && browser.version === '11.0';
// const isIE10 = browser.name === 'Internet Explorer' && browser.version === '10.0';

// 跳離畫面的事件
const alertFun = (e) => {
  e.preventDefault();
  return '';
};

export default {
  mounted() {
    const query = getURLParameters(location.href);
    /**
     * cart main type=5 跳頁離開時警告提示 (a link 跳頁限定， routerLink 無效果)
     */
    if (location.pathname.toLowerCase() === '/cart/main' && query.type === '5') {
      if (isIE11) {
        window.addEventListener('beforeunload', alertFun);
      } else if (isChrome || isFirefox || isEdge) {
        window.document.body.onbeforeunload = () => '';
      }
    }
  },
  beforeDestroy() {
    if (isIE11) {
      window.removeEventListener('beforeunload', alertFun);
    } else if (isChrome || isFirefox || isEdge) {
      window.document.body.onbeforeunload = () => null;
    }
  },
};
