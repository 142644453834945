<template>
  <div class="slider" v-if="marts.length > 0">
    <h4 class="title">
      {{ title }}
      <div class="swiper-pagination" :class="prefixClass" slot="pagination"></div>
    </h4>
    <!-- 如果瀏覽數量或商品收藏數量超過 slidesLimit，則啟動 swiper -->
    <swiper v-if="useSlider" :options="swiperOption">
      <swiper-slide v-for="(mart, index) in marts" :key="index">
        <slider-item :mart="mart"></slider-item>
      </swiper-slide>
      <div class="swiper-button-prev" :class="prefixClass" slot="button-prev"></div>
      <div class="swiper-button-next" :class="prefixClass" slot="button-next"></div>
    </swiper>
    <div v-else class="slider-view swiper-container swiper-container-horizontal">
      <div class="swiper-wrapper">
        <div class="item swiper-slide">
          <slider-item v-for="(mart, index) in marts" :key="index" :mart="mart" class="sliderItemWidth"></slider-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import sliderItem from '@/components/desktop/slider/slider-item.vue';

const components = {
  swiper,
  swiperSlide,
  sliderItem,
};

const props = {
  title: String,
  marts: Array,
  prefix: String,
};

const data = function () {
  return {
    slidesLimit: 5, // 一頁顯示個數及分頁每次切換移動數量
  };
};

const computed = {
  // 是否建立輪播
  useSlider() {
    return this.marts.length > this.slidesLimit;
  },
  prefixClass() {
    return `${this.prefix}Class`;
  },
  swiperOption() {
    return {
      slidesPerView: this.slidesLimit, // 一頁顯示5個
      spaceBetween: 0, // item 中間是否有間隔
      pagination: {
        // 分頁圓點點
        el: `.swiper-pagination.${this.prefixClass}`,
        clickable: true,
      },
      navigation: {
        // 上一頁 < , 下一頁 >
        nextEl: `.swiper-button-next.${this.prefixClass}`,
        prevEl: `.swiper-button-prev.${this.prefixClass}`,
      },
      slidesPerGroup: this.slidesLimit, // 圓點點分組，5 個分一頁
      loop: true, // 重複
      loopFillGroupWithBlank: true, // 一頁不足部分補空白
      lazy: true, // lazyload 載入圖片
    };
  },
};

export default {
  name: 'slider',
  props,
  data,
  components,
  computed,
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only 減少請求 -->
<style scoped src="@/assets/main-slider.css"></style>

<style>
.swiper-pagination-bullet {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 1px 1px 1px 4px;
  cursor: pointer;
  background-color: #dcddde;
  border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  opacity: 0.9;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 10px;
  height: 10px;
  margin: 0 1px 0 4px;
  background-color: #0090d4;
  opacity: 1;
}
</style>
