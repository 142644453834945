<template>
  <div class="alert alert-default alert-linebuy">
    <div class="icon-block">
      <img alt="神腦生活墊子發票證明聯" src="/images/line-points-icon.jpg" width="110" height="110" />
    </div>
    <div class="text">
      <h4>{{ Text_1 }}</h4>
      <p>
        {{ Text_2 }}
        <br />
        <span class="text-muted">{{ Text_3 }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import lang from '@/lang/tw.json';

const data = () => ({
  Text_1: lang.cart.lineTip_1,
  Text_2: lang.cart.lineTip_2,
  Text_3: lang.cart.lineTip_3,
});

export default {
  name: 'LineTip',
  data,
};
</script>

<style scoped>
.alert-linebuy .icon-block {
  float: left;
}
.alert-linebuy .text {
  margin-left: 120px;
}
</style>
