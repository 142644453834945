<template>
  <div class="table-total offset-left-2 offset-right-2">
    <div class="clearfix">
      <!-- Left-->
      <div class="total pull-left fn-sm">
        <div v-if="hasPoint" class="total-item">
          <input class="checkbox" id="bonus" type="checkbox" name="check" v-model="checkPoint" />
          <label class="checkbox-label" for="bonus">
            使用神腦幣折抵
            <b class="value">{{ formatPointMoney }}</b>
          </label>
          <div class="danger spacing left md">
            最高可折抵單筆結帳金額
            <b>{{ pointMaxExchangeRate }}%</b>
          </div>
        </div>
        <div>
          <!-- Hamipoint: 已登入且非R身份且 isFromApp !== 2 者才能顯示-->
          <div class="total-item" v-if="isShowHamiPointBlock">
            <div v-if="isAuthHamiPoint">
              <input class="checkbox" type="checkbox" id="prd-4" :disabled="true" checked />
              <label for="prd-4" class="checkbox-label">
                您已完成中華會員身份驗證，
                <span class="danger"><b>可於【結帳】時，</b></span>
                <p class="danger spacing left md fn-sm">
                  <b>使用 Hami Point 點數折抵</b>
                  (可用 Hami Point 點數: {{ hamiPoint | NumberWithPoint }} 點)
                </p>
              </label>
            </div>
            <!-- Hami Point 點數折抵，中華會員身分驗證前-->
            <div v-else>
              <input
                class="checkbox"
                id="hamipoint"
                type="checkbox"
                name="checkHamipoint"
                v-model="isCheckAuthHamiPoint"
                @change="openModal('HamipointModal')" />
              <label class="checkbox-label" for="hamipoint">
                結帳使用 Hami Point 點數折抵 (1點可折抵1元)
                <p class="fn-sm spacing left md">將前往中華電信驗證會員資料。</p>
                <p class="danger fn-sm spacing left md" v-if="isShowChtLoginMsg">
                  <b>馬上驗證中華會員身份，完成購物即享Hami Point 贈點回饋。</b>
                </p>
              </label>
            </div>
          </div>
          <!-- 宜睿 -->
          <div class="total-item">
            <input
              class="checkbox"
              id="edenred"
              type="checkbox"
              name="checkEdenred"
              :disabled="!isUserNotOwnedEdenredForShowText"
              v-model="isCheckUsedEdenred"
              @change="openModal('EdenredModal')" />
            <label class="checkbox-label" for="edenred">
              結帳時，使用 Edenred 即享券
              <p v-if="isUserNotOwnedEdenredForShowText" class="fn-sm spacing left md">
                將前往驗證 Edenred 即享券序號。
              </p>
              <p v-else class="spacing left md fn-sm">
                已完成驗證，序號
                <span>{{ formatEdenredSerialText }}</span>
                ，
                <span>
                  共{{ getEdenredTotalCount }}筆，合計可用餘額
                  <b class="value">{{ edenredBalanceTotal | dollar }}</b>
                </span>
                <a
                  class="underline spacing left inverted"
                  href="javascript:void(0);"
                  @click="openModal('EdenredModal')">
                  更換序號
                </a>
              </p>
            </label>
          </div>
        </div>
      </div>
      <!-- Right-->
      <div class="total pull-right align right">
        <div class="total-item">
          <span class="label">小計</span>
          <span class="price">{{ subTotal }}</span>
        </div>
        <div v-if="hasPoint" class="total-item">
          <span class="label">神腦幣</span>
          <span class="price">-{{ pointMoney }}</span>
        </div>
      </div>
    </div>
    <auth-hamipoint-alert-modal @cancel="close('hamiPoint')" />
    <edenred-modal @cancel="close('edenred')"></edenred-modal>
  </div>
</template>

<script>
import { commitMutation, renderState, getGetter, commitMutations, getState } from '@/helpers/vuex';
import { NumberWithPoint, dollar } from '@/helpers/format/dollar';
import { openModal } from '@/helpers/modal';
import {
  formatEdenredSerialText,
  edenredBalanceTotal,
  isUserNotOwnedEdenredForShowText,
  getEdenredTotalCount,
} from '@/helpers/edenred';
import { hasPoint } from '@/mixins/cart/main/partial';
import AuthHamipointAlertModal from '@/components/common/modals/hamipoint-modal.vue';
import EdenredModal from '@/components/desktop/main/edenred-modal.vue';

// 是否選用神腦幣的 checkbox 並計算神腦幣折抵金額
const checkPoint = {
  get() {
    return renderState('CartDetail', 'isCheckPoint');
  },
  set(value) {
    commitMutation('CartDetail', 'isCheckPoint', value);
  },
};

const isCheckUsedEdenred = {
  get() {
    return renderState('CartDetail', 'isCheckUsedEdenred');
  },
  set(value) {
    commitMutation('CartDetail', 'isCheckUsedEdenred', value);
  },
};

const props = {
  formatPointMoney: String,
  isAuthHamiPoint: Boolean,
  pointMoney: String,
  subTotal: String,
  hamiPoint: Number,
  pointMaxExchangeRate: Number,
  isShowHamiPointBlock: Boolean,
};

const filters = {
  NumberWithPoint,
  dollar,
};

export default {
  name: 'discount-total',
  filters,
  data() {
    return {
      isCheckAuthHamiPoint: false,
    };
  },
  components: {
    AuthHamipointAlertModal,
    EdenredModal,
  },
  computed: {
    checkPoint,
    getCartDetail: getGetter('CartDetail', 'getterCartDetail'),
    isCheckUsedEdenred,
    isShowChtLoginMsg: getState('CartDetail', 'isShowChtLoginMsg'),
    hasPoint,
    isUserNotOwnedEdenredForShowText,
    formatEdenredSerialText,
    edenredBalanceTotal,
    getEdenredTotalCount,
  },
  methods: {
    openModal,
    close(modalName) {
      switch (modalName) {
        case 'hamiPoint':
          this.isCheckAuthHamiPoint = false;
          break;
        case 'edenred':
          commitMutations('CartDetail', {
            isFetchEdenredHasBalance: false,
            edenredIsError: false,
            edenredErrorTxt: '',
            isCheckUsedEdenred: false,
          });
          break;
        // no default
      }
    },
  },
  props,
};
</script>

<style scoped>
.total-item {
  min-height: 40px;
}
</style>
