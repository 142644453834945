<template>
  <div class="wrapper">
    <div class="container">
      <main class="row">
        <LineTip v-if="isLinePoints"></LineTip>
        <choiceEmpty v-if="isEmpty"></choiceEmpty>
        <MartList v-if="!isEmpty" :cartType="cartType"></MartList>
        <div class="row">
          <mart-slider title="瀏覽紀錄" :marts="browseRecord" :prefix="'browseRecord'" />
        </div>
        <div class="row">
          <mart-slider title="商品收藏" :marts="favorites" :prefix="'favorites'" />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { getFavorite } from '@/api/favorite';
import { getBrowseRecord } from '@/api/browse-record';
import { renderState } from '@/helpers/vuex';
import onbeforeunload from '@/mixins/router-link-onbeforeunload';
import LineTip from '@/components/desktop/main/line-tip.vue';
import MartList from '@/components/desktop/main/mart-list.vue';
import martSlider from '@/components/desktop/slider/slider.vue';
import choiceEmpty from '@/components/common/choice-empty.vue';

// 是否顯示 LinePoint 提示 */
const isLinePoints = function () {
  return Boolean(renderState('CartDetail', 'isLinePoints')) && !this.isEmpty;
};

const data = function () {
  return {
    // 收藏夾
    favorites: [],
    // 瀏覽紀錄
    browseRecord: [],
  };
};

const components = {
  MartList,
  choiceEmpty,
  martSlider,
  LineTip,
};

const computed = {
  isLinePoints,
};

const methods = {
  /** 呼叫瀏覽紀錄 api */
  callHistory(storageHistory) {
    getBrowseRecord(() => {
      if (!storageHistory) {
        return {};
      }
      const martIds = JSON.parse(storageHistory);
      return {
        params: { martIds },
      };
    }).then((res) => {
      if (res.data.code !== 1) {
        return;
      }
      this.browseRecord = res.data.data.map((row) => ({
        url: `/mart/${row.martId}`,
        image: row.imageUrl,
        item_name: row.martName,
        finalPrice: row.finalPrice,
        oriPrice: row.oriPrice,
        alt: row.martName,
        martSalesType: row.martSalesType,
      }));
    });
  },
  /** 呼叫收藏夾 api */
  callFavoriteAPI() {
    getFavorite().then((res) => {
      if (res.data.code !== 1) {
        return;
      }
      if (Object.keys(res.data.data).length <= 0) {
        return;
      }
      this.favorites = res.data.data.map((row, index) => ({
        url: `/mart/${row.martId}`,
        image: row.imageUrl,
        item_name: row.martName,
        finalPrice: row.finalPrice,
        oriPrice: row.oriPrice,
        alt: row.imageAlt,
        martSalesType: row.martSalesType,
        item_id: row.martId,
        index,
      }));
    });
  },
};

const mounted = function () {
  /** 若 localStorage 存有瀏覽紀錄才呼叫 api ; only for PC */
  const storageHistory = localStorage.getItem('browseHistory');
  if (storageHistory !== null) {
    // 瀏覽紀錄：從 local storage 取 martIds 再 call api
    this.callHistory(storageHistory);
  }
  // 判斷登入才能呼叫商品收藏 api ; only for PC
  if (this.isLogin) {
    this.callFavoriteAPI();
  }
};

const props = {
  cartType: String,
  isEmpty: Boolean, // from call-cart-count.js
  isLogin: Boolean,
};

const watch = {
  isLogin() {
    if (this.isLogin) {
      this.callFavoriteAPI();
    }
  },
};

export default {
  name: 'CartMain',
  data,
  mixins: [onbeforeunload],
  components,
  computed,
  methods,
  mounted,
  props,
  watch,
};
</script>

<style scoped>
p.sub {
  line-height: 21px;
  margin-bottom: 0;
}
</style>
